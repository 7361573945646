import React, { useState } from "react";

import Modal from "react-modal";

Modal.setAppElement("#___gatsby");

const customStyles = {
  content: {
    top: "30%",
    transform: "translateY(-50%)",
    maxHeight: "400px",
  },
  overlay: {
    backgroundColor: "rgba(51,51,51,0.6)",
    zIndex: "10000",
  },
};

function CartModal({ order, cart }) {
  const [orderModal, setOrderModal] = useState(false);
  const toggleOpenModal = () => setOrderModal(!orderModal);

  return (
    <div>
      <button className="btn btn-primary" onClick={toggleOpenModal}>
        <span>Items</span>
        <i className="icon-long-arrow-right"></i>
      </button>

      <Modal
        isOpen={orderModal}
        style={customStyles}
        className="container quickView-container"
        onRequestClose={toggleOpenModal}
        center
      >
        <div className="modal-content" style={{ maxHeight: "700px" }}>
          <div className="modal-body mt-4 p-2">
            <div className="row ">
              <div className="col-12">
                <table className="table table-sm table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Product Title</th>
                      <th scope="col">Product Quantity</th>
                      <th scope="col">Product Price</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cart &&
                      cart.map((item) => (
                        <tr key={item.productId}>
                          <td >
                            {" "}
                            {item.productId.title.slice(0, 15)}
                          </td>
                          <td >{item.quantity}</td>
                          <td >{Number(item.price).toFixed(2)}</td>
                          <td>{Number(item.quantity * item.price).toFixed(2)}</td>
                        </tr>
                      ))}
                  </tbody>

                  <tfoot>
                    <tr >
                      {" "}
                      <td></td>
                      <td></td>
                      <td></td>
                      <td><b>Subtotal</b>: $ {Number(order.subtotal).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td><b>Tax</b>: $ {Number(order.tax).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td><b>Shipping</b>: $ {Number(order.shipping).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <b>Total</b>: {"$"}
                        {Number(order.tax + order.shipping + order.subtotal).toFixed(2)}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CartModal;
